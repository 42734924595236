<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div :id="anchorName">
    <p v-if="name" class="dynamic-sub-title mb-10">{{ name }}</p>
    <v-card class="chip-group-table" elevation="0" outlined>
      <v-card-title class="justify-start">
        <v-chip-group
          v-model="selection"
          :show-arrows="true"
          mandatory
          active-class="white--text"
        >
          <v-chip
            v-for="(tag, index) in tags"
            :key="tag"
            :color="selection == index ? 'secondary' : ''"
            :text-color="selection == index ? 'white' : 'secondary'"
            @click="changeTag(index)"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-card-title>

      <v-card-text>
        <div class="html-editor-wrap">
          <div v-html="item" class="html-editor"></div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
import tableHelper from "@/components/dynamic/table/tableHelper.js";
export default {
  mixins: [dynamicBlock, tableHelper],
  data: () => ({
    selection: 0,
  }),
  computed: {
    rows() {
      if (!this.blockData) return [];
      return this.blockData.rows;
    },
    tags() {
      if (!this.rows) return [];
      return this.rows.map((item) => item.name);
    },
    item() {
      if (!this.rows) return "";
      if (!this.rows[this.selection]) return "";
      const target = this.rows.find(
        (item) => item.name === this.rows[this.selection].name
      );
      return target.content;
    },
  },

  methods: {
    async changeTag(index) {
      if(index == this.selection) return
      await this.$nextTick();
      this.wrapTable();

      await this.$nextTick();
      this.$target = $(this.$el).find(".table-wrap")
      this.initScrollbar();
    },
  },
};
</script>